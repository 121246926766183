html {
    scroll-padding-top: 80px;
}

body {
    position: relative;
    padding-top: $navbar-brand-height * 2.5;
}

#header {
    top: 0;

    .navbar-nav {
        .nav-link {
            margin-left: 15px;
        }
    }

    .user-avatar {
        margin-left: 20px;
    }
}

@media print {
    body {
        padding-top: 0;
    }

    #header {
        position: relative;
    }

    #navbar-logo {
        margin: 30px auto 0;
    }
}

#form-welcome {
    .lead {
        font-size: 130%;
    }
}

#sticky-section-menu {
    padding-top: 90;
    margin-top: -90px;
}

.form-section {
    .row {
        margin-bottom: 2rem;
    }
}

select.input-group-unit {
    background-color: $gray-200;
}

.badge {
    cursor: pointer;
}

.toast-container {
    margin-top: 50px;
    z-index: 1090;
}

.fs-sm {
    font-size: 85%;
}

.cursor-pointer {
    cursor: pointer;
}

.offcanvas-50 {
    min-width: 50%;
}

.horizontal-btn-align-end {
    text-align: right;
    .btn-toolbar {
        justify-content: end;
    }
}

.info-button {
    padding: 0.2rem 0.75rem;
}

/***** CLIENT BACKEND *****/

/* SURVEY VIEWS */

.survey-list .survey-status-progress {
    min-width: 100px;
}

.assignment-list-row-toolbar {
    width: 160px;
}

/***** FORM SECTIONS *****/

/* FORM HEADINGS */

.form-content h1,
.form-content h2,
.form-content h3,
.form-content h4,
.form-content h5,
.form-content h6 {
    font-weight: normal;
}

/* CARBON STOCK CHANGE SECTION */

.sus-carbon-stock-table {
    table-layout: fixed;

    tr:nth-child(4n + 2) td,
    tr:nth-child(4n + 3) td,
    tr:nth-child(4n + 2) th,
    tr:nth-child(4n + 3) th {
        background: $gray-100;
    }
}

.sus-carbon-stock-add-change {
    display: none;
}

.sus-carbon-change-cell {
    height: 48px;
    text-align: center;
    vertical-align: top;

    .form-label {
        font-size: 85%;
        line-height: 1rem;
        margin-bottom: 0;
    }
    .form-select {
        @extend .form-select-sm;
        max-width: 250px;
        margin: 0 auto;
    }
    .input-group {
        @extend .input-group-sm;
        justify-content: center;
    }
    .form-control {
        @extend .form-control-sm;
        max-width: 70px;
    }
    .info-button i {
        font-size: 1rem !important;
    }
}

.sus-carbon-change-cell:hover {
    .sus-carbon-stock-add-change {
        display: inline-block;
    }

    .sus-carbon-stock-intermediate-note {
        display: none;
    }
}

.sus-carbon-stock-add-year {
    background-color: transparent;
    border-bottom: 0;
    padding-top: 10px;

    select {
        max-width: 120px !important;
    }
}

/***** LOADING SPINNER *****/

#spinner-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: rgba($color: #ffffff, $alpha: 0.9);
    z-index: 2000; // bootstrap goes till like 1100

    .spinner {
        position: absolute;
        left: 50%;
        top: 30%;
        margin: 0 0 0 -35px;
    }
}
.spinner-inline-wrapper {
    .spinner {
        width: 70px;
        height: 60px;
        text-align: center;
        font-size: 10px;
        margin: 0 auto;
    }
}

#spinner-overlay,
.spinner-inline-wrapper {
    .spinner {
        width: 70px;
        height: 60px;
        text-align: center;
        font-size: 10px;
    }
    .spinner.spinner-small {
        width: 27px;
        height: 22px;
    }

    .spinner-label {
        position: absolute;
        left: 50%;
        top: 30%;
        margin: 80px 0 0 -150px;
        width: 90%;
        max-width: 300px;
        text-align: center;
    }

    .spinner > div {
        background-color: $primary;
        height: 100%;
        width: 8px;
        margin-right: 4px;
        display: inline-block;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner.spinner-small > div {
        width: 3px;
        margin-right: 2px;
    }

    .spinner .lrect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .lrect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .spinner .lrect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .lrect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }
        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
}
